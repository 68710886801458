import { formatDateTime } from '../../utils/formatDate';
import ExternalLink from '../ExternalLink';
import ImagesLink, { ImagesJobLink } from '../ImagesLink';
import { BookingDataActiveJobsColumnName } from './BookingDataActiveJobsListPanel';
import { BookingDataCompletedJobsColumnName } from './BookingDataCompletedJobsListPanel';

interface JobDataLinks {
  mediamathLink: string | null;
  nativeLink: string | null;
  imagesLink: ImagesJobLink;
  facebookLink: string | null;
  yahooLink: string | null;
  adformLink: string | null;
  tradedeskLink: string | null;
  yahooNativeLink: string | null;
}

interface JobDataDetails {
  id: number;
  endpoint: string;
  status: string;
  listingId: number;
  bookingId: string;
  processId: number;
  attemptCount: number;
  processDate?: string;
  lastProcessed?: string | null;
}

type JobData = JobDataDetails & JobDataLinks;

type BookingDataColumnName =
  | BookingDataActiveJobsColumnName
  | BookingDataCompletedJobsColumnName;

export const createJobDataCellRenderers = ({
  id,
  endpoint,
  status,
  listingId,
  mediamathLink,
  nativeLink,
  imagesLink,
  facebookLink,
  yahooLink,
  adformLink,
  tradedeskLink,
  yahooNativeLink,
  bookingId,
  processId,
  attemptCount,
  processDate,
  lastProcessed,
}: JobData): Record<
  BookingDataColumnName,
  () => JSX.Element | string | number | boolean | null
> => ({
  id: () => id,
  endpoint: () => endpoint,
  status: () => status,
  listingId: () => listingId,
  mediamathLink: () =>
    mediamathLink &&
    (mediamathLink === 'N/A' ? (
      mediamathLink
    ) : (
      <ExternalLink href={mediamathLink}>Mediamath link</ExternalLink>
    )),
  nativeLink: () =>
    nativeLink &&
    (nativeLink === 'N/A' ? (
      nativeLink
    ) : (
      <ExternalLink href={nativeLink}>Native link</ExternalLink>
    )),
  imagesLink: () =>
    imagesLink &&
    listingId && (
      <ImagesLink link={imagesLink} listingId={listingId}>
        Images link
      </ImagesLink>
    ),
  facebookLink: () =>
    facebookLink &&
    (facebookLink === 'N/A' ? (
      facebookLink
    ) : (
      <ExternalLink href={facebookLink}>Facebook link</ExternalLink>
    )),
  yahooLink: () =>
    yahooLink &&
    (yahooLink === 'N/A' ? (
      yahooLink
    ) : (
      <ExternalLink href={yahooLink}>Yahoo link</ExternalLink>
    )),
  adformLink: () =>
    adformLink &&
    (adformLink === 'N/A' ? (
      adformLink
    ) : (
      <ExternalLink href={adformLink}>Adform link</ExternalLink>
    )),
  tradedeskLink: () =>
    tradedeskLink &&
    (tradedeskLink === 'N/A' ? (
      tradedeskLink
    ) : (
      <ExternalLink href={tradedeskLink}>Tradedesk link</ExternalLink>
    )),
  yahooNativeLink: () =>
    yahooNativeLink &&
    (yahooNativeLink === 'N/A' ? (
      yahooNativeLink
    ) : (
      <ExternalLink href={yahooNativeLink}>Yahoo native link</ExternalLink>
    )),
  bookingId: () => bookingId,
  processId: () => processId,
  attemptCount: () => attemptCount,
  processDate: () => (processDate ? formatDateTime(processDate) : null),
  lastProcessed: () => (lastProcessed ? formatDateTime(lastProcessed) : null),
});
