import {
  EmptyTableBody,
  Table,
  TableBody,
  TableContentColumn,
  TableHeader,
  TableHeaderColumn,
  TableRow,
} from '../Table/Table';
import BookingDataPanel from './BookingDataPanel';
import { ImagesJobLink } from '../ImagesLink';
import { createJobDataCellRenderers } from './createJobDataCellRenderers';

export interface CompleteJobData {
  id: number;
  endpoint: string;
  status: string;
  listingId: number;
  mediamathLink: string | null;
  nativeLink: string | null;
  imagesLink: ImagesJobLink;
  facebookLink: string | null;
  yahooLink: string | null;
  adformLink: string | null;
  tradedeskLink: string | null;
  yahooNativeLink: string | null;
  bookingId: string;
  processId: number;
  attemptCount: number;
  processDate: string;
}

export interface BookingDataCompletedJobsListPanelProps {
  data: CompleteJobData[];
}

export type BookingDataCompletedJobsColumnName = keyof CompleteJobData;

export const columnLabels: Record<BookingDataCompletedJobsColumnName, string> =
  {
    id: 'ID',
    endpoint: 'Endpoint',
    status: 'Status',
    listingId: 'Listing ID',
    mediamathLink: 'Mediamath link',
    nativeLink: 'Native link',
    imagesLink: 'Images link',
    facebookLink: 'Facebook link',
    yahooLink: 'Yahoo link',
    adformLink: 'Adform link',
    tradedeskLink: 'Tradedesk link',
    yahooNativeLink: 'Yahoo native link',
    bookingId: 'Booking ID',
    processId: 'Process ID',
    attemptCount: 'Attempt count',
    processDate: 'Process date',
  };

const columnNames = Object.keys(
  columnLabels,
) as BookingDataCompletedJobsColumnName[];

const BookingDataCompletedJobsListPanel = ({
  data,
}: BookingDataCompletedJobsListPanelProps): JSX.Element => (
  <BookingDataPanel heading="Jobs History">
    <Table>
      <TableHeader>
        {columnNames.map((columnName) => (
          <TableHeaderColumn key={`th-${columnName}`}>
            {columnLabels[columnName]}
          </TableHeaderColumn>
        ))}
      </TableHeader>
      <TableBody>
        {data.length === 0 ? (
          <EmptyTableBody />
        ) : (
          data.map((dataRow) => {
            // eslint-disable-next-line testing-library/render-result-naming-convention
            const cellRenderers = createJobDataCellRenderers(dataRow);

            return (
              <TableRow key={`tr-${dataRow.id}`}>
                {columnNames.map((columnName) => (
                  <TableContentColumn key={`td-${columnName}`}>
                    {cellRenderers[columnName]()}
                  </TableContentColumn>
                ))}
              </TableRow>
            );
          })
        )}
      </TableBody>
    </Table>
  </BookingDataPanel>
);

export default BookingDataCompletedJobsListPanel;
