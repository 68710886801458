import {
  EmptyTableBody,
  Table,
  TableBody,
  TableContentColumn,
  TableHeader,
  TableHeaderColumn,
  TableRow,
} from '../Table/Table';
import BookingDataPanel from './BookingDataPanel';
import { ImagesJobLink } from '../ImagesLink';
import { createJobDataCellRenderers } from './createJobDataCellRenderers';

export interface ActiveJobData {
  id: number;
  endpoint: string;
  status: string;
  listingId: number;
  mediamathLink: string | null;
  nativeLink: string | null;
  imagesLink: ImagesJobLink;
  facebookLink: string | null;
  yahooLink: string | null;
  adformLink: string | null;
  tradedeskLink: string | null;
  yahooNativeLink: string | null;
  bookingId: string;
  processId: number;
  attemptCount: number;
  lastProcessed: string | null;
}

export interface BookingDataActiveJobsListPanelProps {
  data: ActiveJobData[];
}

export type BookingDataActiveJobsColumnName = keyof ActiveJobData;

export const activeJobColumnLabels: Record<
  BookingDataActiveJobsColumnName,
  string
> = {
  id: 'ID',
  endpoint: 'Endpoint',
  status: 'Status',
  listingId: 'Listing ID',
  mediamathLink: 'Mediamath Link',
  nativeLink: 'Native Link',
  imagesLink: 'Images Link',
  facebookLink: 'Facebook Link',
  yahooLink: 'Yahoo Link',
  adformLink: 'Adform Link',
  tradedeskLink: 'Tradedesk Link',
  yahooNativeLink: 'Yahoo native Link',
  bookingId: 'Booking ID',
  processId: 'Process ID',
  attemptCount: 'Attempt Count',
  lastProcessed: 'Last Processed',
};

const activeJobColumnNames = Object.keys(
  activeJobColumnLabels,
) as BookingDataActiveJobsColumnName[];

const BookingDataActiveJobsListPanel = ({
  data,
}: BookingDataActiveJobsListPanelProps): JSX.Element => (
  <BookingDataPanel heading="Active Jobs List">
    <Table>
      <TableHeader>
        {activeJobColumnNames.map((activeJobColumnName) => (
          <TableHeaderColumn key={`th-${activeJobColumnName}`}>
            {activeJobColumnLabels[activeJobColumnName]}
          </TableHeaderColumn>
        ))}
      </TableHeader>
      <TableBody>
        {data.length === 0 ? (
          <EmptyTableBody />
        ) : (
          data.map((dataRow) => {
            // eslint-disable-next-line testing-library/render-result-naming-convention
            const activeJobCellRenderers = createJobDataCellRenderers(dataRow);

            return (
              <TableRow key={`tr-${dataRow.id}`}>
                {activeJobColumnNames.map((activeJobColumnName) => (
                  <TableContentColumn key={`td-${activeJobColumnName}`}>
                    {activeJobCellRenderers[activeJobColumnName]()}
                  </TableContentColumn>
                ))}
              </TableRow>
            );
          })
        )}
      </TableBody>
    </Table>
  </BookingDataPanel>
);

export default BookingDataActiveJobsListPanel;
